<template>
    <div class="container-fluid">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                <div v-if="(id > 0)" class="col-sm-6">
                    <h1>{{ $t('general.perito') }}</h1>
                </div>
                <div v-else class="col-sm-6">
                    <h1>{{ $t('general.nuevoperito') }}</h1>
                </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="row">
                <!-- Ocultar esta columna en la creación -->
                <div v-if="(id > 0)" class="col-md-2">
                    <!-- Datos Perito -->
                    <div class="card bg-gradient-primary">
                        <div class="card-body box-profile">
                            <h3 class="profile-username text-center">{{ datos.nombre }}</h3>
                            <p class="text-muted text-center text-white">{{ datos.empresa }}</p>
                        </div>
                    </div>
                    <div class="card card-primary card-outline">
                        <div class="card-body box-profile">
                            <ul class="list-group list-group-unbordered mb-3">
                            <li class="list-group-item">
                                <b>{{ $t('general.servicios') }}</b> <a class="float-right">301</a>
                            </li>
                            <li class="list-group-item">
                                <b>{{ $t('general.serviciosactivos') }}</b> <a class="float-right">45</a>
                            </li>
                            </ul>
                        </div>
                    </div>
                    <div class="callout callout-info">
                    <h5>{{ $t('general.ultimamodificacion') }}</h5>
                    <p> {{ fechamod }}</p>
                    </div>
                </div>
                <div class="col-md-10">
                    <div v-if="(id > 0)" class="card card-default card-tabs">
                        <div class="card-header p-1 pt-1">
                            <ul class="nav nav-pills">
                                <li class="nav-item"><a class="nav-link active" href="#datos_perito" data-toggle="tab">{{ $t('general.datos') }}</a></li>  
                            </ul>
                        </div>
                    </div>
                    <br>
                    <div class="tab-content">
                        <div class="active tab-pane" id="datos_perito">
                            <perito-datos
                            :datos="datos"
                            :id="datos.id" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import PeritoDatos from './datos/PeritoDatos.vue'
import { PwgsApi } from '../../../services/PwgsApi';
export default {
    props: ['id'],
    components: {
        'perito-datos': PeritoDatos,
    },      
    data() {
        return {
            datos: "",
            fechamod: "",
            nombre: "",
            titulo: "",
        }
    },
    methods: {  
      async cargarDatosPerito(id) {
            const api = new PwgsApi();
            let aux  =  await api.get('peritos/' + id);
            this.datos = aux.datos;
            this.fechamod = this.datos.fecha_actualiza_perito.slice(8,10) + "-" + this.datos.fecha_actualiza_perito.slice(5,7) + "-" +  this.datos.fecha_actualiza_perito.slice(0,4) + " | " + this.datos.fecha_actualiza_perito.slice(10,16);
            this.nombre = this.datos.nombre;
            this.titulo = this.datos.empresa;
            /**/
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;
            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana == '/peritos/' + ventanaid) {
                    this.$store.getters.ventanas[i].name = this.datos.nombre;
                    this.$store.getters.ventanas[i].alt = "Perito: " + this.datos.nombre + " || " + this.datos.empresa;
                }
            }
            /**/
      },
    },
    watch: {
      id(){
            this.cargarDatosPerito(this.id);
        },
        $route() {
            /**/
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;
            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana == '/peritos/' + ventanaid) {
                    this.$store.getters.ventanas[i].name = this.datos.nombre;
                    this.$store.getters.ventanas[i].alt = "Perito: " + this.datos.nombre + " || " + this.datos.empresa;
                }
            }
            /**/
      }
  },
  activated() {
      this.cargarDatosPerito(this.id);     
  }
}
</script>
